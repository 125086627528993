import { render, staticRenderFns } from "./o-plugin-sidebar.vue?vue&type=template&id=617953a5"
import script from "./o-plugin-sidebar.vue?vue&type=script&lang=ts"
export * from "./o-plugin-sidebar.vue?vue&type=script&lang=ts"
import style0 from "./o-plugin-sidebar.vue?vue&type=style&index=0&id=617953a5&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../common/temp/node_modules/.pnpm/vue-loader@15.11.1_c2c4f8d10a02e12364bd9a7337709f82/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports