import { render, staticRenderFns } from "./overlay.layout.vue?vue&type=template&id=83c32054"
import script from "./overlay.layout.vue?vue&type=script&lang=ts"
export * from "./overlay.layout.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../common/temp/node_modules/.pnpm/vue-loader@15.11.1_c2c4f8d10a02e12364bd9a7337709f82/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports